<template>
    <div>
        <div class="entry-short" v-bind:style="{ backgroundImage: 'url(' + cmsPageHeaderImage + ')' }">
            <div class="context">
                <h1 class="error-title">404</h1>
                <h2 v-html="errorSentence"></h2>
                <img src="@/assets/404.png" class="error-image">
                
            </div>
        </div>
    </div>
</template>

<script>
    import { globalOptionQuery } from '@/queries'
    import { config } from '@/config'

    export default {
        name: 'PageNotFound',

        components: {},

        data() {
            return {
                event: null,
                route: this.$route
            }
        },

        apollo: {
            defaultCmsPageHeader: {
                query: globalOptionQuery,
                variables: {
                    optionId: 'default-cms-page-header'
                },
                fetchPolicy: 'cache-first',
                update: data => data.globalOptions.data[0]
            }
        },

        metaInfo() {},

        computed: {
            errorSentence() {
                const randomKey = Math.floor(Math.random() * 10)
                return this.$t(`error_404_${randomKey}`)
            },

            image() {
                return this.getImageUrl('/uploads/404.png')
            },

            cmsPageHeaderImage() {
                return this.defaultCmsPageHeader ? this.getImageUrl(this.localize(this.defaultCmsPageHeader.attributes.image).data.attributes.url) : ''
            },
        },

        methods: {}
    }
</script>

<style lang="scss" scoped>
    .entry-short {
        min-height: 100vh;

        h1 {
            font-size: 42px;
        }

        h2 {
            font-size: 24px;
            padding: 0 30px;
        }

        .error-title {
            font-family: getFontFamily('pageTitle');
        }

        .error-image { 
            width: 80vw;
            max-height: 400px;
            object-fit: contain;
            display: block;
            margin-top: 50px;
            margin-left: auto;
            margin-right: auto;
        }
    }
</style>
